import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationCodeComponent } from './all-modules/component/authentication-code/authentication-code.component';
import { HomeComponent } from './all-modules/pages/home/home.component';
import { PrivacyPolicyComponent } from './all-modules/pages/privacy-policy/privacy-policy.component';
import { DefaultLayoutComponent } from './containers';
import { ResetPasswordComponent } from './shared/component/reset-password/reset-password.component';
import { VerifyMemberComponent } from './shared/component/verify-member/verify-member.component';

export const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./all-modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'auth/:invitationId', loadChildren: () => import('./all-modules/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: DefaultLayoutComponent,
    loadChildren: () => import('./all-modules/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'retainer',
    loadChildren: () => import('./all-modules/pages/pages.module').then(m => m.PagesModule),
  },
  { path: 'home', component: HomeComponent, data: { title: 'home' } },
  { path: 'verify-member/:id', component: VerifyMemberComponent, data: { title: 'verify member' } },
  { path: 'verify-member-password/:id', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
  {
    path: 'family-forms',
    loadChildren: () => import('../app/all-modules/pages/matters/family/family.module').then(m => m.FamilyModule),
  },
  {
    path: 'immigration-forms',
    loadChildren: () =>
      import('../app/all-modules/pages/matters/immigration/immigration.module').then(m => m.ImmigrationModule),
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'privacy' } },
  {
    path: 'auth-code/:id',
    component: AuthenticationCodeComponent,
    data: { title: 'Authentication Code' },
  },
  {
    path: 'cash-payment-receipt',
    loadChildren: () => import('../app/all-modules/component/component.module').then(m => m.ComponentModule),
    data: { title: 'Cash Payment Receipt' },
  },

  // wild card routing
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
