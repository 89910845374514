import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService, DataService } from '../services';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  token: string;
  apiCount = 0;
  constructor(private router: Router, private auth: AuthService, private data: DataService, private spinner: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.apiCount++;
    this.auth.setHeader();
    request = request.clone({ headers: this.auth.headers });
    this.spinner.show();

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            this.apiCount--;
            this.disableSpinner();

            if (event.body) {
              const token = event.headers.get('X-auth-token');

              if (token) {
                this.auth.setToken(token);
                this.data.setUserDetail(event.body);
              }
            }
          }
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            this.apiCount--;
            this.disableSpinner();

            if (error?.status === 401) {
              const url = window.location.pathname;
              if (url !== '/auth/login') {
                this.data.setRedirectUrl(url);
              }

              this.router.navigateByUrl('/auth/login');
            }
            return throwError(error);
          }
        }
      )
    );
  }

  disableSpinner() {
    if (this.apiCount === 0) {
      this.spinner.hide();
    }
  }
}
