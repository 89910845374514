import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as INFO from '../../../enum/info-messages.enum';
import * as MESSAGE from '../../../enum/info-messages.enum';
import * as LOCALSTORAGE from '../../../enum/local-storage.enum';
import { LOCAL_STORAGE } from '../../../enum/local-storage.enum';
import { ROUTES_TO } from '../../../enum/routes.enum';
import { ApiService, CommonService, DataService, GlobalErrorHandlerService, OneSignalService } from '../../../services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchOverdue', { static: true }) searchOverdue: ElementRef;
  @ViewChild('searchToday', { static: true }) public searchToday: ElementRef;
  windowWidth: number = window.innerWidth;
  destroy$ = new Subject<boolean>();

  private endpoints = Endpoints;
  private matterId: string;
  private lawyersDetail: any;
  private matterDetails: any;
  public overDueTasksList = [];
  public todaysTask = [];
  public query: string;
  public querySearch: string;
  public todaysClaimSteps = [];
  public filteredTodaysTasks = [];
  public filteredOverdueTasks = [];
  public searchFilter: any = '';
  public preFilter = {
    preType: '',
    preStatus: '',
  };

  overduePageLimit = 10;
  agendaPageLimit = 10;
  agendaPageNo = 1;
  overduePageNo = 1;
  searchOverduePageNo = 1;
  searchAgendaPageNo = 1;
  overdueTotalPage: number;
  agendaTotalPage: number;
  genericTasks: any;
  eventTaskData: any;
  currentPage: number;
  showCommonEvent = false;
  showModal = false;
  searchOverduePagination = false;
  searchTodaysPagination = false;
  searchedText: string;
  searchOverdueTotalPage: number;
  searchAgendaTotalPage: number;
  overdueMatterType: string;
  agendaMatterType: string;
  userDetails: any;
  matterType: string;
  accessLevel: number;
  notificationsData: any;

  constructor(
    private router: Router,
    private dataService: DataService,
    private commonService: CommonService,
    private api: ApiService,
    private toastr: ToastrService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private oneSignalService: OneSignalService,
    private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails) {
      this.accessLevel = this.userDetails.accessLevel;
    }
    this.dataService.setAddMatterButtonActive(false);
    this.dataService.setMatterDetailActive(false);
    this.dataService.setUserDetailActive(false);
    this.commonService.showSidebarCalendar(false);
    this.agendaPageChanged(this.agendaPageNo);
    this.overduePageChanged(this.overduePageNo);
    this.commonService.updateNotificationBadge.next(true);
    await this.getMatterId();
    await this.getNotifications();
  }

  async ngAfterViewInit() {
    this.searchTodaysTasks();
    this.searchOverdueTasks();
  }

  addMatter() {
    this.router.navigate([ROUTES_TO.MATTERS], { queryParams: { addMatter: true } });
  }

  addNewMember() {
    this.router.navigate(['/law-firm-members'], { queryParams: { addMember: true } });
  }

  addPrecedent() {
    this.router.navigate(['/precedents'], { queryParams: { addPrecedent: true } });
  }

  /**
   * @description Method is used to get the generic events
   */
  getGenericEvents() {
    this.api
      .getParams(`${this.endpoints.genericEvents}`, { pageNumber: this.agendaPageNo, limit: this.agendaPageLimit })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.genericTasks = res.events;
          }
        },
        (err) => {
          this.globalErrorHandlerService.handleError(err);
        }
      );
  }

  onClickGenericEvent(genericEv: any) {
    this.eventTaskData = genericEv;
    this.showCommonEvent = true;
  }

  private searchOverdueTasks(): void {
    fromEvent(this.searchOverdue?.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => {
          if (!res) {
            this.searchedText = '';
            this.searchOverduePageNo = 1;
            if (this.overdueMatterType) {
              this.searchMatter(this.searchedText, this.searchOverduePageNo, 'overdue', this.overdueMatterType);
            } else {
              this.overduePageNo = 1;
              this.overduePageChanged(this.overduePageNo);
            }
          }
          if (res.length > 3) {
            this.searchOverduePagination = true;
            return res;
          } else {
            this.searchedText = '';
            this.searchOverduePagination = false;

            return res;
          }
        }),
        debounceTime(1000)
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((searchedInput: string) => {
        if (searchedInput.length > 3) {
          this.searchedText = searchedInput;
          this.filteredOverdueTasks = [];
          this.searchOverduePagination = true;
          this.currentPage = 1;
          this.searchMatter(this.searchedText, this.currentPage, 'overdue', this.agendaMatterType);
        } else {
          this.searchedText = '';
        }
      });
  }

  searchMatter(searchText, currentPage?: number, type?: string, matterType?: string) {
    const id = this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetails?._id : '';
    const api: any = this.api.getParams(`${this.endpoints.searchTasks}`, {
      searchText: searchText,
      pageNumber: currentPage,
      limit: this.overduePageLimit,
      type: type,
      matterType: matterType,
      userId: id,
    });
    try {
      let overDueTasks = [];

      api.pipe(takeUntil(this.destroy$)).subscribe(
        (res: any) => {
          if (res) {
            overDueTasks = res?.tasks;

            const type = res.whichType;
            if (type === 'overdue') {
              this.searchOverdueTotalPage = res.totalTask;
              this.filteredOverdueTasks = [...overDueTasks].map((matter: any) => {
                if (matter?.tasks) {
                  const matterTasks = matter.tasks;
                  matterTasks.matter = matter;
                  matterTasks.user = matter.user;

                  return matterTasks;
                }
              });
            } else {
              this.searchAgendaTotalPage = res.totalTask;
              this.filteredTodaysTasks = [...overDueTasks].map((matter: any) => {
                if (matter?.tasks) {
                  const matterTasks = matter.tasks;
                  matterTasks.matter = matter;
                  matterTasks.user = matter.user;

                  return matterTasks;
                }
              });
            }
          }
        },
        (err) => {
          const errResponse = err.error.message || INFO.RESPONSE.NOMATTER;
          this.toastr.error(errResponse, INFO.TOASTR.ALERT);
          this.filteredOverdueTasks = [...this.overDueTasksList];
        }
      );
    } catch (err) {}
  }

  searchTodaysTasks() {
    fromEvent(this.searchToday?.nativeElement, 'keyup')
      .pipe(
        map((event: any) => {
          return event.target.value;
        }),
        filter((res) => {
          if (!res) {
            this.searchedText = '';
            this.searchAgendaPageNo = 1;
            if (this.agendaMatterType) {
              this.searchMatter(this.searchedText, this.searchAgendaPageNo, 'overdue', this.agendaMatterType);
            }
          }
          if (res.length > 3) {
            this.searchTodaysPagination = true;
            return res;
          } else {
            this.searchedText = '';

            this.searchTodaysPagination = false;
            this.filteredTodaysTasks = [...this.todaysTask];
            return;
          }
        }),
        debounceTime(1000)
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((searchedInput: string) => {
        if (searchedInput.length > 3) {
          this.searchedText = searchedInput;
          this.filteredTodaysTasks = [];
          this.searchTodaysPagination = true;
          this.currentPage = 1;
          this.searchMatter(this.searchedText, this.currentPage, 'agenda', this.agendaMatterType);
        } else {
          this.searchedText = '';
          this.filteredTodaysTasks = [...this.todaysTask];
        }
      });
  }

  onConfirm(ev) {
    this.showCommonEvent = false;
    if (ev.isAdd) {
      this.getGenericEvents();
    }

    if (ev.canDelete) {
      this.showCommonEvent = false;
      this.showModal = true;
    }
  }

  deleteEventModal(ev) {
    this.showModal = false;
    if (ev.state) {
      this.deleteEvent();
    }
  }

  deleteEvent() {
    this.api
      .delete(`${Endpoints.addEvent}/${this.eventTaskData._id}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.toastr.success(MESSAGE.TOASTR.MESSAGE_EVENTDELETE);
          this.getGenericEvents();
        },
        (err) => {
          let errMessage = err.error || MESSAGE.RESPONSE.ERROR;
          this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        }
      );
  }

  /**
   *
   * @param event Page number
   * @description Method is used to get the overdue tasks
   */
  overduePageChanged(event: number) {
    this.overdueMatterType = '';
    this.filteredOverdueTasks = [];
    this.overDueTasksList = [];
    this.overduePageLimit !== undefined || null ? this.overduePageLimit : 10;
    this.overduePageNo = event;
    const id = this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetails?._id : '';
    this.api
      .getParams(`${this.endpoints.getOverdueTasks}`, {
        pageNumber: this.overduePageNo,
        limit: this.overduePageLimit,
        userId: id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.overDueTasksList = res.tasks;
            this.overdueTotalPage = res.totalTask;
            this.filteredOverdueTasks = [...this.overDueTasksList];
          }
        },
        (err) => {
          this.globalErrorHandlerService.handleError(err);
        }
      );
  }

  searchOverduePageChanged(ev: number) {
    this.searchOverduePageNo = ev;
    this.searchMatter(this.searchedText, this.searchOverduePageNo, 'overdue', this.overdueMatterType);
  }

  /**
   *
   * @param ev Page number
   * @description Method is used to get the todays agenda
   */
  agendaPageChanged(ev: number) {
    this.agendaMatterType = '';
    this.agendaPageNo = ev;
    const id = this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetails?._id : '';

    this.api
      .getParams(`${this.endpoints.getAgenda}`, {
        pageNumber: this.agendaPageNo,
        limit: this.agendaPageLimit,
        userId: id,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.todaysTask = res.tasks;
            this.agendaTotalPage = res.totalTask;

            this.filteredTodaysTasks = this.todaysTask;
            this.todaysTask.forEach((ele) => {
              ele?.todaysClaimProgressStep?.forEach((claim) => {
                this.todaysClaimSteps.push(claim);
              });
            });
            this.todaysClaimSteps = [...new Map(this.todaysClaimSteps.map((item) => [item['_id'], item])).values()];
          }
        },
        (err) => {
          if (err.status === 403) {
            this.logOut();
          }

          this.globalErrorHandlerService.handleError(err);
        }
      );

    if (ev === 1) {
      this.getGenericEvents();
    }
  }

  searchAgendaPageChanged(ev: number) {
    this.searchAgendaPageNo = ev;
    this.searchMatter(this.searchedText, this.searchAgendaPageNo, 'agenda', this.agendaMatterType);
  }

  logOut() {
    this.dataService.getSelectedUserData.next(null);
    this.dataService.matterDetailActive.next(false);
    const userName = localStorage.getItem('userName');
    localStorage.clear();
    localStorage.setItem('userName', userName);

    this.router.navigate([ROUTES_TO.LOGIN]);
  }

  /**
   *
   * @param matterId
   * @param task
   * @description Method is used to navigate to the briefs and dockets
   */
  goToMatter(matterId: string, task: any) {
    localStorage.removeItem(LOCALSTORAGE.LOCAL_STORAGE.MATTER_NAV_DETAILS);
    const matterInfo = { userDetail: task.user, matterDetails: task.matter };
    this.dataService.getSelectedUserData.next(matterInfo);
    this.router.navigate([`/matters/${matterId}/brief-dockets`]);
  }

  /**
   * @description Method is used to navigate to the data and forms page
   */
  navigateToForms() {
    this.router.navigate([ROUTES_TO.DATA_FORMS]);
  }

  formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  /**
   *
   * @param pretype Matter type
   * @param whichType Task type
   */
  filterTasks(pretype, whichType?: string) {
    const preFilter = {
      preType: pretype,
      preStatus: '',
    };

    !pretype ? (whichType === 'overdue' ? this.overduePageChanged(1) : this.agendaPageChanged(1)) : null;

    const searchedText = this.searchedText ? this.searchedText : '';

    if (whichType === 'agenda') {
      this.agendaMatterType = pretype;
      this.searchTodaysPagination = pretype ? true : false;
      this.searchAgendaPageNo = 1;
      this.agendaMatterType ? this.searchMatter(searchedText, this.searchAgendaPageNo, whichType, this.agendaMatterType) : null;
    } else {
      this.overdueMatterType = pretype;
      this.searchOverduePagination = pretype ? true : false;
      this.searchOverduePageNo = 1;

      this.overdueMatterType ? this.searchMatter(searchedText, this.searchOverduePageNo, whichType, this.overdueMatterType) : null;
    }
  }

  private getLawyers(): void {
    this.api
      .get(this.endpoints.getLawyer)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          if (data) {
            this.lawyersDetail = data;
            this.lawyersDetail = this.lawyersDetail.map((lawyer) => {
              lawyer['fullName'] = `${lawyer.firstName} ${lawyer.middleName} ${lawyer.lastName}`;
              lawyer['_id'] = lawyer._id;
              return lawyer;
            });
          }
        },
        (error) => {
          this.globalErrorHandlerService.handleError(error);
        }
      );
  }

  private async getMatterDetails(matterId: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getLawyers();
      this.api
        .get(`${this.endpoints.getMatter}/${matterId}`)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: any) => {
            this.matterDetails = {};

            if (res) {
              this.matterDetails = res;
              this.matterDetails.lawyersDetail = this.lawyersDetail;
              const matterInfo = { userDetail: res.user, matterDetails: res };
              localStorage.setItem(LOCAL_STORAGE.MATTER_NAV_DETAILS, JSON.stringify(matterInfo));
              this.dataService.getSelectedUserData.next(matterInfo);
              this.dataService.setMatterDetailActive(true);
              this.dataService.setMatterDetails(res);
              this.matterDetails ? localStorage.setItem(LOCAL_STORAGE.MATTER_DETAILS, JSON.stringify(this.matterDetails)) : '';
              this.commonService.updateSideMenu.next(true);
              resolve(true);
            } else {
              reject(false);
            }
          },
          (error) => {
            reject(false);
            this.globalErrorHandlerService.handleError(error);
          }
        );
    });
  }

  private async getMatterId(): Promise<void> {
    this.activatedRoute.paramMap.subscribe(async (params) => {
      this.matterId = params.get(LOCAL_STORAGE.MATTER_ID);
    });
  }

  private async getNotifications() {
    const data = await this.oneSignalService.getNotifications(this.matterId || '', this.userDetails);
    this.notificationsData = data.slice(0, 5);
  }

  async navigateTo(notification, index: number): Promise<void> {
    const res = await this.getMatterDetails(notification.matterId);
    if (res) {
      if (notification.notificationType === 'message') {
        await this.router.navigate([`/matters/${notification.matterId}/chat`], {
          queryParams: { matterId: notification.matterId },
        });
      } else if (notification.notificationType === 'document') {
        await this.router.navigate([`/matters/${notification.matterId}/documents`], {
          queryParams: { notification: true },
        });
      } else {
        await this.router.navigate([`matters/${notification.matterId}/approvals`], {
          queryParams: { matterId: notification.matterId },
        });
      }
      const notificationRead = {
        viewed: true,
      };

      if (!notification?.viewed) {
        this.api
          .put(`${this.endpoints.readNotification}/${notification._id}`, notificationRead)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: () => {
              this.notificationsData[index].viewed = true;
            },
            error: (error) => {
              console.log('🚀 ~ file: notifications.component.ts:63 ~ NotificationsComponent ~ this.api.put ~ error:', error);
            },
          });
      }
    } else {
      this.toastr.error('Something went wrong', 'Error');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
