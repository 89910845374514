import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CashPaymentReceiptComponent } from './cash-payment-receipt/cash-payment-receipt.component';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
const routes = [
  {
    path: ':receiptId',
    component: CashPaymentReceiptComponent,
  },
];

@NgModule({
  declarations: [CashPaymentReceiptComponent],
  imports: [RouterModule.forChild(routes), CommonModule, ModalModule, AngularSignaturePadModule],
  exports: [CashPaymentReceiptComponent],
})
export class ComponentModule {}
